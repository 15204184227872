<template>
    <div>
        <v-overlay
            :value="loader"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-btn small @click="openOperationsRemarkDialog()" class="mt-3" v-if="$can('approve','Epr')">{{ $t('message.sendRemarkToSalesColleague') }}</v-btn>
        <v-data-table
            :headers="headers"
            :items="items"
            class="appic-table-light specification-table pt-3 pb-3 px-0 col-lg-6 col-sm-12 col-md-6"
            dense
            disable-pagination
            hide-default-footer
            item-key="EprRemark.id"
        ></v-data-table>
        <v-dialog
            persistent
            scrollable
            transition="dialog-top-transition"
            v-model="operationsRemarkDialog"
            width="500px"
            max-width="500px !important"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.sendRemark') }}</v-card-title>
                <v-card-text>
                    <v-form lazy-validation ref="remarkForm">
                        <v-layout row pt-3>
                            <v-flex xs6 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.subject') }}</v-flex>
                            <v-flex xs6 lg8 md7 py-1>
                                <v-select
                                    :items="subjects"
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-model="remark.subject"
                                ></v-select>
                            </v-flex>
                        </v-layout>
                        <v-layout row pt-3>
                            <v-flex xs6 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.message') }}</v-flex>
                            <v-flex xs6 lg8 md7 py-1>
                                <v-textarea
                                    :rules="[v => !!v || this.$t('message.required')]"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="remark.text = $event"
                                ></v-textarea>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="operationsRemarkDialog = false"
                    >
                        {{ $t('message.close') }}
                    </v-btn>
                    <v-btn
                        :disabled="remark.subject == null"
                        :loading="loading.send"
                        small
                        @click="sendRemark()"
                    >
                        {{ $t('message.send') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import { api } from "../../api";

    export default {
        name: "EprRemarks",
        props: ['eprId','eprStatusId'],
        data(){
            return {
                items: [],
                loader: false,
                loading: {
                    send: false
                },
                operationsRemarkDialog: false,
                subjects: [
                    {value: 'Incomplete Information', text: 'Incomplete Information'}
                ],
                remark: {
                    epr_id: null,
                    operation_id: null,
                    subject: 'Incomplete Information',
                    text: null
                }
            }
        },
        computed: {
            ...mapGetters('user',{
                User: 'User'
            }),
            canPost () {
                return ['teesc@apptimber.com','weiwen@apptimber.com','vanjoe@apptimber.com'].includes(this.User.email)
            },
            headers() {
                return [
                    {
                        id: 0,
                        text: this.$t('message.date'),
                        value: 'EprRemark.sent_date',
                        sortable: false,
                    },
                    {
                        id: 1,
                        text: this.$t('message.sentBy'),
                        value: 'EprRemark.sentby',
                        sortable: false,
                    },
                    {
                        id: 2,
                        text: this.$t('message.subject'),
                        value: 'EprRemark.subject',
                        sortable: false,
                    },
                    {
                        id: 3,
                        text: this.$t('message.remark'),
                        value: 'EprRemark.text',
                        sortable: false,
                    }
                ]
            }
        },
        methods: {
            getRemarks() {
                let promise = new Promise((resolve, reject)=>{
                    api
                        .get('/eprs/' + this.eprId + '/remarks')
                        .then((response) => {
                            resolve(response.data.data)
                        })
                        .catch((error)=>{
                            reject(error)
                        })
                })
                this.loader = true
                promise
                    .then((data) => {
                        this.items = data
                        this.loader = false
                    })
                    .catch(() => {
                        this.loader = false
                    })
            },
            openOperationsRemarkDialog() {
                this.operationsRemarkDialog = true
            },
            sendRemark() {
                if(this.$refs.remarkForm.validate()){
                    this.loading.send = true
                    let promise = new Promise((resolve, reject) => {
                        api
                            .post('/eprs/' + this.eprId + '/remarks', {
                                remark: this.remark
                            })
                            .then(() => {
                                resolve('done')
                            })
                            .catch((error) => {
                                reject(error)
                            })
                    })
                    promise
                        .then(() => {
                            this.loading.send = false
                            this.operationsRemarkDialog = false
                            this.getRemarks()
                            this.$toast.success(this.$t('message.successes.eprRemarkSent'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.eprRemarkNotSent'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.send = false
                        })
                }
            }
        },
        created(){
            this.remark.epr_id = this.eprId
            this.remark.operation_id = this.User.email
            if(this.eprId != null) {
                this.getRemarks()
            }
        }
    }
</script>

<style>

</style>